import React, {useState} from 'react'
import {InputText} from "primereact/inputtext";
import {Button} from "primereact/button";
import ConfigUtil from "../../../util/ConfigUtil";
import FetchUtil from "../../../util/FetchUtil";
import {Navigate} from "react-router-dom";
import {NAVIGATION_ITEM} from "../../../constants/navigationItems";
import ErrorInfoComponent from "../../general/ErrorInfoComponent";

export default function StartseiteVideoInvitation() {

    const [infoMessage, setInfoMessage] = useState(undefined);
    const [errorMessage, setErrorMessage] = useState(undefined);

    let [gameId, setGameId] = useState(undefined);
    let [meetingKey, setMeetingKey] = useState("");

    const detectMeeting = () => {
        setErrorMessage(undefined);
        if (meetingKey !== undefined && meetingKey !== null && meetingKey.length > 0) {
            let url = ConfigUtil.getConfig().resourceUrls.game + "/detectMeeting?meetingkey=" + meetingKey;
            FetchUtil.fetchGet(url,
                gameid => {
                    setGameId(gameid)
                },
                responseNotOk => {
                    setErrorMessage("Fehler: " + responseNotOk.message);
                },
                error => {
                    setErrorMessage("Fehler: " + error.message);
                });
        }
    }

    const handleKeyDown = (e) => {
        if (e.keyCode === 13) {
            // enter
            detectMeeting();
        }
    }

    if (gameId) {
        return <Navigate
            to={NAVIGATION_ITEM.GAME_VIDEO.route + "?gameid=" + gameId + "&playerid=ZuschauerId"}/>;
    }

    return <div>
        <ErrorInfoComponent infoMessage={infoMessage} errorMessage={errorMessage} onClearInfoMessage={() => setInfoMessage(undefined)} onClearErrorMessage={() => setErrorMessage(undefined)} />
        <h3>Du hast eine Meetingkey (Key) zu einem Spiel?</h3>
        <div style={{display: "flex"}}>
            <InputText name="meetingkey" style={{width: 300, marginRight:10}} value={meetingKey}
                       placeholder="Meetingkey hier eintragen"
                       onKeyDown={(e) => handleKeyDown(e)}
                       onChange={(e) => setMeetingKey(e.target.value.toUpperCase())}/>
            <Button label="Meeting ermitteln" style={{width: 150}} type="button"
                    onClick={() => detectMeeting()} disabled={meetingKey === ""}/>
        </div>
    </div>
}
