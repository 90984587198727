import React, {useState} from 'react'
import {InputText} from "primereact/inputtext";
import {Button} from "primereact/button";
import ConfigUtil from "../../../util/ConfigUtil";
import FetchUtil from "../../../util/FetchUtil";
import {Navigate} from "react-router-dom";
import {NAVIGATION_ITEM} from "../../../constants/navigationItems";
import ErrorInfoComponent from "../../general/ErrorInfoComponent";

export default function StartseiteGameWatchInvitation() {

    const [infoMessage, setInfoMessage] = useState(undefined);
    const [errorMessage, setErrorMessage] = useState(undefined);

    let [gameId, setGameId] = useState(undefined);
    let [key, setKey] = useState("");

    const detectGame = () => {
        setErrorMessage(undefined);
        if (key !== undefined && key !== null && key.length > 0) {
            let url = ConfigUtil.getConfig().resourceUrls.game + "/detectMeeting?meetingkey=" + key;
            FetchUtil.fetchGet(url,
                gameid => {
                    setGameId(gameid)
                },
                responseNotOk => {
                    setErrorMessage(responseNotOk.message);
                },
                error => {
                    setErrorMessage("Fehler: " + error.message);
                });
        }
    }


    const handleKeyDown = (e) => {
        if (e.keyCode === 13) {
            // enter
            detectGame();
        }
    }

    if( gameId ) {
        let url = NAVIGATION_ITEM.GAMEWATCH.route + "?gameid=" + gameId;
        return <Navigate to={url}/>;
    }

    return <div>
            <ErrorInfoComponent infoMessage={infoMessage} errorMessage={errorMessage} onClearInfoMessage={() => setInfoMessage(undefined)} onClearErrorMessage={() => setErrorMessage(undefined)} />
            <h3>Einladung zu einem Game?</h3>
            <div style={{display:"flex"}}>
                <InputText name="scorekey" style={{width: 300, marginRight:10}} value={key}
                           placeholder="Scorekey hier eintragen"
                           onKeyDown={(e) => handleKeyDown(e)}
                           onChange={(e) => setKey(e.target.value.toUpperCase())}/>
                <Button label="Game ermitteln" style={{width:150}} type="button" onClick={() => detectGame()} disabled={key === ""}/>
            </div>
        </div>
}
