import React, {useState} from 'react'
import KachelOverviewContainer from "../../../containers/views/start/KachelOverviewContainer";
import LigaDashboardContainer from "../../../containers/views/start/LigaDashboardContainer";
import {MANDANTTYPES} from "../../../constants/mandantTypes";
import PropTypes, {instanceOf} from "prop-types";
import {Cookies} from "react-cookie";
import {isEventOperatorAreaByCookieValue} from "../../../util/CookiesHelper";
import VeranstalterDashboardContainer from "../../../containers/views/veranstalter/VeranstalterDashboardContainer";
import DashboardSelectionArea from "./DashboardSelectionArea";
import NewsArea from "../../general/NewsArea";

function Dashboard({mandantType, cookies}) {

    let [showNews, setShowNews] = useState(true);
    let ligaDashboard = mandantType === MANDANTTYPES.DEFAULT.bezeichnung;

    return <div>
        {ligaDashboard && <DashboardSelectionArea isOnlineDashboard={ligaDashboard && !isEventOperatorAreaByCookieValue(cookies)} cookies={cookies}/>}
        {ligaDashboard && !isEventOperatorAreaByCookieValue(cookies) ? <LigaDashboardContainer/> : ""}
        {ligaDashboard && isEventOperatorAreaByCookieValue(cookies) ? <VeranstalterDashboardContainer/> : ""}
        {!ligaDashboard ? <KachelOverviewContainer/> : ""}
        {showNews && <NewsArea onClose={() => setShowNews(false)}/>}
    </div>
}

Dashboard.propTypes = {
    mandantType: PropTypes.string.isRequired,
    cookies: instanceOf(Cookies).isRequired,
};

export default Dashboard
