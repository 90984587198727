import {
    LOGIN_BY_TOKEN_FAILED,
    LOGIN_BY_TOKEN_SUCCESSFUL,
    AUTODARTS_BOARDID_SAVE,
    LOGIN_FAILED,
    LOGIN_SUCCESSFUL,
    LOGOUT_FAILED,
    LOGOUT_SUCCESSFUL,
    REQUEST_LOGIN,
    REQUEST_LOGIN_BY_TOKEN,
    REQUEST_LOGOUT, REQUEST_SIGNUP, SET_EVENT_OPERATOR,
    SIGNUP_FAILED, SIGNUP_SUCCESSFUL,
} from "../actions/user";

function user(state = {
    id: undefined,
    name: undefined,
    isLoggedIn: false,
    isLoggingIn: false,
    isLoggingOut: false,
    isSigningUp: false,
}, action) {
    switch (action.type) {
        case REQUEST_LOGIN:
            return {
                ...state,
                isLoggingIn: true
            };
        case LOGIN_SUCCESSFUL:
            return {
                ...state,
                isLoggingIn: false,
            };
        case LOGIN_FAILED:
            return {
                ...state,
                isLoggingIn: false
            };
        case REQUEST_LOGIN_BY_TOKEN:
            return {
                ...state,
                name: undefined,
                isLoggedIn: false,
                isLoggingIn: true
            };
        case SET_EVENT_OPERATOR:
            return {
                ...state,
                userEventOperatorAdmin: true,
            };
        case AUTODARTS_BOARDID_SAVE:
            return {
                ...state,
                autodartsBoardId: action.boardId,
            };
        case LOGIN_BY_TOKEN_SUCCESSFUL:
            return {
                ...state,
                id: action.userInfo.id,
                mandantType: action.userInfo.mandantType,
                name: action.userInfo.name,
                userRoles: action.userInfo.userRoles,
                userEventOperatorAdmin: action.userInfo.userEventOperatorAdmin,
                isLoggedIn: true,
                isLoggingIn: false,
                autodartsBoardId: action.userInfo.autodartsBoardId,
            };
        case LOGIN_BY_TOKEN_FAILED:
            return {
                ...state,
                id: undefined,
                name: undefined,
                isLoggedIn: false,
                isLoggingIn: false
            };
        case REQUEST_LOGOUT:
            return {
                ...state,
                isLoggingOut: true
            };
        case LOGOUT_SUCCESSFUL:
            return {
                ...state,
                id: undefined,
                name: undefined,
                isLoggedIn: false,
                isLoggingOut: false
            };
        case LOGOUT_FAILED:
            return {
                ...state,
                isLoggingOut: false
            };
        case REQUEST_SIGNUP:
            return {
                ...state,
                isSigningUp: true
            };
        case SIGNUP_SUCCESSFUL:
            return {
                ...state,
                isSigningUp: false,
            };
        case SIGNUP_FAILED:
            return {
                ...state,
                isSigningUp: false
            };
        default:
            return state
    }
}

export default user;
