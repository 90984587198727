import {connect} from 'react-redux'
import {login} from "../actions/user";
import Login from '../components/Login'

const mapStateToProps = state => ({
    isLoggingIn: state.user.isLoggingIn
});

const mapDispatchToProps = dispatch => ({
    login: (name, password, afterLoginCallback, errorCallback) => dispatch(login(name, password, afterLoginCallback, errorCallback)),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Login)