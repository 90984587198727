import {connect} from 'react-redux'
import withRouter from "../../../components/RouterComponentWrapper";
import LigaspieltagDetail from "../../../components/views/tournament/LigaspieltagDetail";

const mapStateToProps = function (state, ownProps) {
    const urlParams = new URLSearchParams(ownProps.location.search);
    const turnierid = urlParams.get('turnierid');
    return {tournamentId: turnierid, userId: state.user.id};
};

const mapDispatchToProps = dispatch => ({
});


export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(LigaspieltagDetail))
