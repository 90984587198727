import React, {useEffect, useState} from "react";
import {TableContainer} from "@mui/material/";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import {Button} from "primereact/button";
import {InputNumber} from "primereact/inputnumber";
import FetchUtil from "../../../util/FetchUtil";
import ConfigUtil from "../../../util/ConfigUtil";
import DHDialog from "../../general/DHDialog";
import ErrorInfoComponent from "../../general/ErrorInfoComponent";

export default function BestleistungenDialog({eventOperatorId, tournamentId, onClose}) {

    const [infoMessage, setInfoMessage] = useState(undefined);
    const [errorMessage, setErrorMessage] = useState(undefined);

    let [ bestleistungen, setBestleistungen] = useState([]);
    let [ showDetailDialog, setShowDetailDialog] = useState(false);

    let [selectedValues, setSelectedValues] = useState(undefined);

    useEffect(() => {
        ladeBestleistungen();
    }, [eventOperatorId, tournamentId]);


    const ladeBestleistungen = () => {
        if( (tournamentId === null || tournamentId === undefined) && (eventOperatorId === null || eventOperatorId === undefined)) {
            return;
        }
        let url = "";
        if( tournamentId !== null && tournamentId !== undefined) {
            url = ConfigUtil.getConfig().resourceUrls.bestleistung + "/tournament/" + tournamentId;
        } else if( eventOperatorId !== null && eventOperatorId !== undefined) {
            url = ConfigUtil.getConfig().resourceUrls.bestleistung + "/eventoperator/" + eventOperatorId;
        }
        FetchUtil.fetchGet(url,
            json => {
                setBestleistungen(json)
            },
            responseNotOk => {
                setErrorMessage("Fehler beim Laden" + responseNotOk.message);
            },
            error => {
                setErrorMessage("Fehler beim Laden" + error.message);
        });
    }

    const save = (data) => {
        let url = "";
        if( tournamentId !== null && tournamentId !== undefined) {
            url = ConfigUtil.getConfig().resourceUrls.bestleistung + "/tournament/save/" + tournamentId;
        } else if( eventOperatorId !== null && eventOperatorId !== undefined) {
            url = ConfigUtil.getConfig().resourceUrls.bestleistung + "/eventoperator/save/" + eventOperatorId;
        }
        FetchUtil.fetchPost(url,
            data,
            json => {
                ladeBestleistungen()
                setShowDetailDialog(false);
            },
            responseNotOk => {
                setErrorMessage("Fehler: " + responseNotOk.message);
            },
            error => {
                setErrorMessage("Fehler: " + error.message);
            }
        );
    }

    const changeValue = (value) => {
        let newBestleistungen = bestleistungen.filter(bestleistung => bestleistung.type !== selectedValues.type);
        let oldBestleistungen = bestleistungen.filter(bestleistung => bestleistung.type === selectedValues.type)[0];
        newBestleistungen.push({eventOperatorId: null, tournamentId: null, type: selectedValues.type,
            von: selectedValues.detailType === "von" ? value : oldBestleistungen.von,
            bis: selectedValues.detailType === "bis" ? value : oldBestleistungen.bis});
        save(newBestleistungen);
    }

    function BestleistungDetailDialog({wert, minValue, maxValue, onSave, onClose}) {

        let [ value, setValue] = useState(wert);

        return <DHDialog onClose={() => onClose()} title="Bestleistungen" show={true}
                         comp={<>
            <div>
                <InputNumber value={value} style={{width: 50}} onChange={(e) => setValue(e.value)} min={minValue}/>
            </div>
            <div style={{marginTop:10}}>
                <Button label="Übernehmen" icon="pi pi-times" onClick={() => onSave(value)} disabled={value === null || value > 180}/>
                <Button label="Abbrechen" icon="pi pi-times" onClick={() => onClose()} className="p-button-secondary" style={{marginLeft:5}}/>
            </div>
        </>}/>
    }

    const getHighscoreRow = () => {
        if( bestleistungen.length === 0) {
            return "";
        }
        let bestleistung = bestleistungen.filter(bestleistung => bestleistung.type === "highscore")[0];
        return <TableRow key="key_highscore" >
            <TableCell style={{fontSize: 18}}>Highscore</TableCell>
            <TableCell style={{fontSize: 18}}  onClick={() => {
                setSelectedValues({type: "highscore", detailType:"von", value:bestleistung.von, min:1, max:180});
                setShowDetailDialog(true);}
            }>
                {bestleistung.von}</TableCell>
            <TableCell style={{fontSize: 18}}  onClick={() => {
                setSelectedValues({type: "highscore", detailType:"bis", value:bestleistung.bis, min:1, max:180});
                setShowDetailDialog(true);}
            }>
                {bestleistung.bis}</TableCell>
        </TableRow>
    }

    const getHighfinsihRow = () => {
        if( bestleistungen.length === 0) {
            return "";
        }
        let bestleistung = bestleistungen.filter(bestleistung => bestleistung.type === "highfinish")[0];
        return <TableRow key="key_highfinish">
            <TableCell style={{fontSize: 18}}>Highfinish</TableCell>
            <TableCell style={{fontSize: 18}}  onClick={() => {
                setSelectedValues({type: "highfinish", detailType:"von", value:bestleistung.von, min:1, max:180});
                setShowDetailDialog(true);}
            }>
                {bestleistung.von}</TableCell>
            <TableCell style={{fontSize: 18}}  onClick={() => {
                setSelectedValues({type: "highfinish", detailType:"bis", value:bestleistung.bis, min:1, max:180});
                setShowDetailDialog(true);}
            }>
                {bestleistung.bis}</TableCell>
        </TableRow>
    }

    const getShortgame = () => {
        if( bestleistungen.length === 0) {
            return "";
        }
        let bestleistung = bestleistungen.filter(bestleistung => bestleistung.type === "shortgame")[0];
        return <TableRow key="key_shortgame">
            <TableCell style={{fontSize: 18}}>Shortgame</TableCell>
            <TableCell style={{fontSize: 18}}  onClick={() => {
                setSelectedValues({type: "shortgame", detailType:"von", value:bestleistung.von, min:1, max:100});
                setShowDetailDialog(true);}
            }>
                {bestleistung.von}</TableCell>
            <TableCell style={{fontSize: 18}}  onClick={() => {
                setSelectedValues({type: "shortgame", detailType:"bis", value:bestleistung.bis, min:1, max:100});
                setShowDetailDialog(true);}
            }>
                {bestleistung.bis}</TableCell>
        </TableRow>
    }

    const footer = (
        <div>
            <Button label="Schliessen" icon="pi pi-times" onClick={() => onClose()} className="p-button-secondary" />
        </div>
    );

    return <DHDialog onClose={() => onClose()} title="Bestleistungen" show={true} buttons={footer}
                     comp={<>
        <ErrorInfoComponent infoMessage={infoMessage} errorMessage={errorMessage} onClearInfoMessage={() => setInfoMessage(undefined)} onClearErrorMessage={() => setErrorMessage(undefined)}/>
        <TableContainer style={{maxHeight: "100%", paddingBottom: 0}}>
            <Table style={{color: "white"}}>
                <TableHead>
                    <TableRow>
                        <TableCell
                            style={{backgroundColor: "#ac1c27", color: "white", fontSize: 18}}>Bestleistung</TableCell>
                        <TableCell style={{backgroundColor: "#ac1c27", color: "white", fontSize: 18}}>Wert
                            von</TableCell>
                        <TableCell style={{backgroundColor: "#ac1c27", color: "white", fontSize: 18}}>Wert
                            bis</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {getHighscoreRow()}
                    {getHighfinsihRow()}
                    {getShortgame()}
                </TableBody>
            </Table>
        </TableContainer>
        {showDetailDialog && <BestleistungDetailDialog onClose={() => setShowDetailDialog(false)} minValue={selectedValues.min} maxValue={selectedValues.max} wert={selectedValues.value} onSave={(value) => changeValue(value)} />}
    </>}/>
}
