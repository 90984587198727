import React from 'react'
import {Button} from 'primereact/button';

import Keyboard from 'react-simple-keyboard';
import layout from "simple-keyboard-layouts/build/layouts/german";
import 'react-simple-keyboard/build/css/index.css';

import PropTypes from "prop-types";
import { instanceOf } from 'prop-types';

import FetchUtil from "../../../../util/FetchUtil";
import ConfigUtil from "../../../../util/ConfigUtil";
import {Stomp} from "@stomp/stompjs";
import SockJS from "sockjs-client";
import {Sidebar} from "primereact/sidebar";

import { withCookies, Cookies } from 'react-cookie';

class PublicKeyComponent extends React.Component {

    constructor(props) {
        super(props);

        const { cookies } = props;

        let publicKey = "";
        if( props.publicKey !== undefined && props.publicKey !== "") {
            publicKey = props.publicKey;
        } else {
            publicKey = cookies.get('darthelfer_public_key') || "";
        }

        this.state = {
            publicKey: publicKey,
            keyMessage: "Du bist aktuell nicht verbunden...",
            messageColor: "red",
            showKeyBoard: false,
            layoutName: "default",
        };

        this.validateAndConnect = this.validateAndConnect.bind(this);
        this.websocketConnect = this.websocketConnect.bind(this);
        this.onMessagePublicKeyReceived = this.onMessagePublicKeyReceived.bind(this);
    }

    componentDidMount() {
        if( this.state.publicKey !== undefined && this.state.publicKey !== null) {
           this.validateAndConnect();
        }
    }

    validateAndConnect() {

        if( !this.state.publicKey ) {
            return;
        }
        this.props.clearErrorMessage();

        const { cookies } = this.props;

        FetchUtil.fetchGet(ConfigUtil.getConfig().resourceUrls.public + "/validate/key/" + this.state.publicKey,
            json => {
                if (json) {
                    this.props.setPublicKey(this.state.publicKey);
                    var date = new Date();
                    date.setDate(date.getDate() + 31);
                    let options = {expires: date, path: '/'};
                    cookies.set('darthelfer_public_key', this.state.publicKey, options);
                    this.websocketConnect();
                } else {
                    this.setState({...this.state, keyMessage:"Du bist nicht mit dem Server verbunden, evtl. ungültiger 'Public Key'...", messageColor:"red"});
                }
            },
            responseNotOk => {
                this.setState({...this.state, keyMessage:"Du bist nicht mit dem Server verbunden...", messageColor:"red"});
                this.props.showError(responseNotOk.message);
            },
            error => {
                this.setState({...this.state, keyMessage:"Du bist nicht mit dem Server verbunden...", messageColor:"red"});
                this.props.showError(error.message);
            });
    }

    websocketConnect() {
        let stompClient = Stomp.over(function () {
            return new SockJS(ConfigUtil.getConfig().websocketUrl);
        });
        stompClient.reconnect_delay = 10000;
        // stompClient.debug = function (str) {}; // Disable debug logging
        stompClient.connect({},
            (x) => {
                console.log("Websocket - connect success");
                stompClient.subscribe("/topic/public/training/" + this.state.publicKey, this.props.onMessageReceived);
                stompClient.subscribe("/topic/public/publickey/" + this.state.publicKey, this.onMessagePublicKeyReceived);
                stompClient.subscribe("/topic/public/reload/" + this.state.publicKey, this.props.onMessageReceivedReload);
                this.setState({...this.state, showKeyBoard: false});
            },
            (x) => {
                console.log("Websocket - connect error:");
            },
            (x) => {
                console.log("Websocket - disconnect:");
            });
    }

    onMessagePublicKeyReceived(payload) {
        console.log("Websocket PublicKey - message received:");
        let payloadBody = payload.body;
        const json = JSON.parse(payloadBody);
        this.setState({...this.state, keyMessage: json.keyMessage, messageColor: json.messageColor});
    }

    onChange = input => {
        this.setState({
            publicKey: input, messageColor: "black", keyMessage: "Public Key noch 'Anwenden'"
        });
    };

    onKeyPress = button => {
        if( button === "{enter}") {
            this.setState({...this.state, showKeyBoard: false});
        }

        /**
         * If you want to handle the shift and caps lock buttons
         */
        if (button === "{shift}" || button === "{lock}") this.handleShift();
    };

    handleShift = () => {
        let layoutName = this.state.layoutName;
        this.setState({
            layoutName: layoutName === "default" ? "shift" : "default"
        });
    };

    render() {
        return <div className="col-fixed" style={{width: "400px", marginLeft: "5px", borderRight: "1px solid"}}>
            <div style={{marginBottom: "10px", fontWeight: "bold", width: "400px"}}>
                <span style={{color: this.state.messageColor}}>{this.state.keyMessage}</span>
            </div>
            <div>
                <Button icon={this.state.showKeyBoard ? "pi pi-angle-double-up" : "pi pi-angle-double-down"}
                        style={{marginLeft: "5px", marginRight: "10px"}}
                        onClick={() => this.setState({...this.state, showKeyBoard: !this.state.showKeyBoard})}
                tooltip={this.state.showKeyBoard ? "Tastatur ausblenden" : "Tastatur einblenden"}/>
                <span style={{fontSize: "20px"}}>Public Key: <span style={{fontWeight: "bold"}}>{this.state.publicKey}</span></span>
                <Button label="Anwenden" style={{marginLeft: "5px"}} onClick={this.validateAndConnect} disabled={this.state.publicKey.length !== 5}/>
            </div>
            <Sidebar visible={this.state.showKeyBoard} style={{height: "300px"}}
                     onHide={(e) => this.setState({...this.state, showKeyBoard: false})}
                     position="bottom" showCloseIcon={false}>
                <Keyboard
                    layout={layout.layout}
                    layoutName={this.state.layoutName}
                    onChange={input => this.onChange(input)}
                    onKeyPress={button => this.onKeyPress(button)}/>
            </Sidebar>
        </div>
    }
}

PublicKeyComponent.propTypes = {
    publicKey: PropTypes.string,
    setPublicKey: PropTypes.func.isRequired,
    onMessageReceived: PropTypes.func.isRequired,
    onMessageReceivedReload: PropTypes.func.isRequired,
    showError: PropTypes.func.isRequired,
    clearErrorMessage: PropTypes.func.isRequired,
    showInfo: PropTypes.func.isRequired,
    cookies: instanceOf(Cookies).isRequired
};

export default withCookies(PublicKeyComponent);
